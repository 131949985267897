.product-page {
    &__shape {
        position: absolute;
        bottom: 100%;
    }
}

.product {
    &__title {
        font-size: 2rem;
    }
}
