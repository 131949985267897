.text-link {
    line-height: 1.5rem;
    font-size: rem-calc(18);
    color: map-deep-get($colors, "brand", "secondary");
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    font-family: $font-family-eurostile;
    letter-spacing: 0.05em;
    font-weight: 800;
    transition: $transition-default;

    svg {
        transition: $transition-default;
        fill: map-deep-get($colors, "brand", "secondary");
        height: 1.5rem;
        width: 1.5rem;
        position: relative;
    }

    &:hover {
        text-decoration: none;
        color: map-deep-get($colors, "brand", "secondary");

        svg {
            fill: map-deep-get($colors, "brand", "secondary");
        }
    }

    &--shadow {
        text-shadow: $shadow-text;

        svg {
            filter: drop-shadow($shadow-text);
        }
    }

    &--primary {
        color: map-deep-get($colors, "brand", "primary");

        svg {
            fill: map-deep-get($colors, "brand", "primary");
        }

        &:hover {
            color: map-deep-get($colors, "brand", "primary");

            svg {
                fill: map-deep-get($colors, "brand", "primary");
            }
        }
    }

    &--white {
        color: map-deep-get($colors, "white", "default");

        svg {
            fill: map-deep-get($colors, "white", "default");
        }

        &:hover {
            color: map-deep-get($colors, "white", "default");
            opacity: .8;

            svg {
                fill: map-deep-get($colors, "white", "default");
            }
        }
    }

    &--icon {
        svg {
            margin-left: 0;
            margin-right: 1rem;
        }

        &:hover {
            svg {
                margin-left: 0;
                margin-right: 1.125rem;
                transform: scale(1.1);
            }
        }
    }

    &--smaller {
        font-size: .875rem;
    }

    &--icon-left {
        svg {
            margin-left: 0;
            margin-right: 1rem;
        }

        &:hover {
            svg {
                margin-left: .25rem;
                margin-right: .75rem;
            }
        }
    }

    &--icon-right {
        svg {
            margin-left: 1rem;
            margin-right: 0;
        }

        &:hover {
            svg {
                margin-left: 1.25rem;
                margin-right: -.25rem;
            }
        }
    }

    &--back {
        svg {
            margin-left: 0;
            margin-right: 1rem;
        }

        &:hover {
            svg {
                margin-left: -.25rem;
                margin-right: 1.25rem;
            }
        }
    }

    &--down {
        svg {
            margin-left: 0;
            margin-right: 1rem;
            top: 0;
        }

        &:hover {
            svg {
                top: .25rem;
            }
        }
    }

    &--up {
        svg {
            margin-left: 0;
            margin-right: 1rem;
            top: 0;
        }

        &:hover {
            svg {
                top: -.25rem;
            }
        }
    }
}
