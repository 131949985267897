/**
  site selection --- language selection
 */

.topbar {
    height: $topbar-height;
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 10;
    background-color: map-deep-get($colors, "brand", "primary-dark");
}

.topbar_dummy { width: 50%; }
