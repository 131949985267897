.portal-selection {
    display: flex;
    position: relative;
    margin-top: 2.75rem;
    flex-direction: column;
    $elm: &;

    @include media-breakpoint-up(sm) {
        flex-direction: row;
    }

    @include media-breakpoint-up(md) {
        min-height: initial;
        height: 75vw;
    }

    @include media-breakpoint-up(lg) {
        height: 66.667vw;
    }

    @include media-breakpoint-up(xl) {
        height: 56.25vw;
    }

    &__block {
        position: relative;
        transition: .5s;
        min-height: 75vw;

        @include media-breakpoint-up(sm) {
            width: 50%;
            min-height: initial;
        }

        &--local {
            #{$elm}__logo {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: 1rem;
                z-index: 98;

                @include media-breakpoint-up(sm) {
                    left: 100%;
                    transform: translateX(-56%);
                }
            }
        }

        &--global {
            #{$elm}__logo {
                display: none;
            }

            #{$elm}__content {
                &::before {
                    transform: matrix(-1, 0, 0, 1, 0, 0);
                }
            }

            #{$elm}__title {
                margin-bottom: 0;
            }

            #{$elm}__sub-title {
                margin-bottom: $sizer-md;
            }
        }
    }

    &__image {
        width: 100%;
        height: 100%;
        position: absolute;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    &__content {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 4rem 1.5rem;
        min-height: 75vw;

        @include media-breakpoint-up(sm) {
            min-height: initial;
            height: 100%;
        }

        &::before {
            background: linear-gradient(90deg, #555555 0%, #303030 100%);
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            opacity: 0.3;
            transition: $transition-default;
        }
    }

    &__title {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        margin-bottom: 0;
        color: map-deep-get($colors, "white", "default");
        z-index: 10;
        text-shadow: $shadow-text;
        transition: $transition-default;
    }

    &__sub-title {
        margin-left: auto;
        margin-right: auto;
        color: map-deep-get($colors, "brand", "secondary");
        z-index: 10;
        font-family: $font-family-eurostile;
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 100%;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        text-shadow: 0 3px 9px rgba(0, 0, 0, 0.32);
        margin-bottom: $sizer-md;

        @include media-breakpoint-up(md) {
            font-size: 1.5rem;
        }
    }

    &__paragraph {
        z-index: 10;
        visibility: hidden;
        opacity: 0;
        line-height: .5;
        min-width: 250px;
        font-family: $font-family-eurostile;
        font-size: 24px;
        max-height: 0;
        margin: 0 auto;
        text-align: center;
        color: map-deep-get($colors, "white", "default");
        transition: $transition-default;

        @include media-breakpoint-up(md) {
            min-width: 380px;
            width: 380px;
        }
        @include media-breakpoint-up(lg) {
            width: 500px;
        }
        @include media-breakpoint-down(sm) {
            width: 75vw;
        }
    }

    &__button {
        padding: 18px;
        border-radius: 4px;
        color: map-deep-get($colors, "white", "default");

        svg {
            fill: map-deep-get($colors, "white", "default");
        }

        &--blue {
            background-color: map-deep-get($colors, "brand", "primary");
        }

        &--red {
            background-color: map-deep-get($colors, "brand", "secondary");
        }
    }

    &--select-local {
        display: flex;

        #{$elm}__block {
            &--local {
                @include media-breakpoint-up(sm) {
                    width: 66%;
                }

                #{$elm}__content::before {
                    opacity: 0.9;
                    background: linear-gradient(90deg, map-deep-get($colors, "brand", "primary") 0, map-deep-get($colors, "brand", "primary-dark") 100%);
                }

                #{$elm}__paragraph {
                    visibility: visible;
                    opacity: 1;
                    max-height: 100vh;
                    line-height: 1.25;
                    margin-bottom: $sizer-xl;
                }

                #{$elm}__title {
                    text-shadow: none;
                }
            }

            &--global {
                @include media-breakpoint-up(sm) {
                    width: 34%;
                }
            }
        }
    }

    &--select-global {
        display: flex;

        #{$elm}__sub-title {
            color: map-deep-get($colors, "brand", "primary");
            text-shadow: none;
        }

        #{$elm}__block {
            &--global {
                @include media-breakpoint-up(sm) {
                    width: 66%;
                }

                #{$elm}__content::before {
                    opacity: 0.9;
                    background: linear-gradient(90deg, #eb5937 0%, #e42313 100%);
                    transform: matrix(-1, 0, 0, 1, 0, 0);
                }

                #{$elm}__paragraph {
                    visibility: visible;
                    opacity: 1;
                    max-height: 100vh;
                    line-height: 1.25;
                    margin-bottom: $sizer-xl;

                    @include media-breakpoint-down(sm) {
                        margin-bottom: $sizer-md;
                    }
                }

                #{$elm}__title {
                    text-shadow: none;
                }
            }

            &--local {
                @include media-breakpoint-up(sm) {
                    width: 34%;
                }
            }
        }
    }
}
