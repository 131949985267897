.hero {
    $elm: &;
    position: relative;
    display: flex;
    align-items: flex-end;
    width: 100vw;
    height: 150vw;

    @include media-breakpoint-up(sm) {
        height: 100vw;
    }

    @include media-breakpoint-up(md) {
        height: 75vw;
    }

    @include media-breakpoint-up(lg) {
        height: 66.667vw;
    }

    @include media-breakpoint-up(xl) {
        height: 50vw;
    }

    &__video-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        overflow: hidden;
        height: 150vw;

        @include media-breakpoint-up(sm) {
            height: 100vw;
        }

        @include media-breakpoint-up(md) {
            height: 75vw;
        }

        @include media-breakpoint-up(lg) {
            height: 66.667vw;
        }

        @include media-breakpoint-up(xl) {
            height: 50vw;
        }

        &::before {
            content: "";
            position: absolute;
            display: block;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1;
            opacity: .5;
            background: $gradient-left-up;
        }
    }

    &__video {
        margin: auto;
        position: absolute;
        object-fit: cover;
        font-family: "object-fit: cover;";
        height: 100%;
        width: 100%;

        &--fullscreen {
            object-fit: contain;
            font-family: "object-fit: contain;";
        }
    }

    &__content {
        position: relative;
        width: 100%;
        z-index: 10;
        color: map-deep-get($colors, "white", "default");

        @include media-breakpoint-up(md) {
            padding-bottom: 3.75rem;
        }

        @include media-breakpoint-up(xl) {
            padding-bottom: 5rem;
        }

        /* stylelint-disable */
        p {
            margin-bottom: $sizer-xl;
        }
        /* stylelint-enable */
    }

    &__close-button {
        bottom: $sizer-section;
        position: absolute;
        opacity: 0.3;
        visibility: hidden;
        padding: $sizer-sm;
        height: $sizer-xxl;
        width: $sizer-xxl;
        z-index: 550;
        border: 1px solid map-deep-get($colors, "gray", "light");
        border-radius: $border-radius;

        &--enabled {
            visibility: visible;
        }

        &:hover {
            opacity: 1;
        }
    }

    &__actions {
        height: 50px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: 1rem;

        @include media-breakpoint-up(md) {
            flex-direction: row;
            align-items: center;
            margin-bottom: -25px;
        }

        /* stylelint-disable */
        .text-link {
            @include media-breakpoint-down(sm) {
                display: inline-block !important;
            }
        }

        a {
            display: inline-flex;
            align-items: center;
            margin-right: rem-calc(80);

            &:last-of-type {
                margin-right: 0;
            }

            @include media-breakpoint-down(md) {
                margin-right: rem-calc(30);
            }

            @include media-breakpoint-down(sm) {
                margin-right: 0;
            }
        }
        /* stylelint-enable */
    }

    &__spacer {
        z-index: 3;
    }

    &__text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
    }

    &__title {
        color: #ffffff;
    }

    &__play-video-link {
        color: #ffffff;
    }

    &__button {
        position: relative;
        z-index: 1;
        margin-bottom: 1rem;

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }
    }

    &__cookie-message {
        background-color: rgba(map-deep-get($colors, "brand", "primary"), .5) !important;
        border: 1px solid map-deep-get($colors, "brand", "secondary");
        color: map-deep-get($colors, "white", "default");
        font-size: .625rem;
        font-weight: 700;
        padding: 1rem;
        position: absolute;
        right: 0;
        text-align: center;
        text-transform: uppercase;
        bottom: 0;
        z-index: 10;

        @include media-breakpoint-up(md) {
            font-size: .75rem;
        }

        a {
            color: map-deep-get($colors, "white", "default");
            font-weight: 700;
            text-decoration: underline;
        }
    }
}
