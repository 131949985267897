.form {
    overflow: hidden;
    transition: $transition-default;
    $form: &;

    &__group-wrapper {
        &--half {
            #{$form}__group:last-of-type {
                margin-bottom: rem-calc(24);
            }

            @include media-breakpoint-up(md) {
                display: flex;
                margin: 0 rem-calc(-8);

                #{$form}__group {
                    margin: 0 rem-calc(8) rem-calc(24);
                    width: 50%;
                }
            }
        }

        &--vspace-small {
            #{$form}__group {
                margin-bottom: rem-calc(12);
            }
        }
    }

    &__input--text,
    &__input--email,
    &__input--number,
    &__input--textarea,
    &__input--select {

        padding-top: 10px;

        &:focus,
        &[data-state="filled"] {
            + .form__label--compact {
                font-size: 12px;
                top: 5px;
            }
        }

        &:-webkit-autofill {
            + .form__label--compact {
                font-size: 12px;
                top: 5px;
            }
        }
    }

    &__input--textarea {
        padding-top: 20px;
    }

    &__group {
        position: relative;
        margin-bottom: rem-calc(24);

        &:nth-child(&) {
            margin-bottom: 0;
        }

        > #{$form}__label {
            display: none;
            margin: 0;
            color: map-deep-get($colors, "brand", "primary-light");

            &--compact {
                display: block;
                position: absolute;
                top: 13px;
                left: 16px;
                pointer-events: none;
                font-weight: 400;
                color: map-deep-get($colors, "gray", "medium");
                transition: all .1s $transition-default-smoothing;
            }
        }
    }

    &__button {
        color: map-deep-get($colors, "white", "default");
    }

    &__button--arrow {
        /* stylelint-disable */
        svg {
            margin-left: 0.75rem;
            margin-right: 0;
        }

        &-left {
            svg {
                margin-left: 0;
                margin-right: 0.75rem;
            }
        }
        /* stylelint-enable */
    }

    &__invalid-message {
        color: map-deep-get($colors, "validation", "invalid");
        display: none;

        // Eigen v-cloak variant omdat de webkey formulieren niet te bewerken zijn
        /* stylelint-disable */
        &[show] {
            display: block;
        }

        /* stylelint-enable */
    }

    &--loading {
        opacity: .5;
    }

    &__input.input {
        &--invalid {
            color: map-deep-get($colors, "validation", "invalid");
            border: 1px solid map-deep-get($colors, "validation", "invalid");
            outline: none;
        }

        &--search {
            color: map_deep-get($colors, "black", "default");
            border-color: map_deep-get($colors, "gray", "light");
        }
    }
}
