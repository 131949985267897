.breadcrumb {
    background-color: transparent;
    padding: 0;

    @include media-breakpoint-down(sm) {
        display: none;
    }

    &__item {
        @extend .breadcrumb-item; /* from bootstrap */
        font-family: $font-family-eurostile;
        font-size: rem-calc(14);
        letter-spacing: 0.1em;
        opacity: 0.5;
        /* stylelint-disable */
        a {
            svg {
                height: rem-calc(16);
                width: rem-calc(16);
                margin-top: -5px;
            }
        }
        /* stylelint-enable */

        &--uppercase {
            text-transform: uppercase;
        }

        &:hover{
            opacity: 1;
        }

        &:last-child {
            opacity: 1;
            color: map-deep-get($colors, "brand", "primary");
        }

        &::before {
            vertical-align: -10%;
        }
    }
}
