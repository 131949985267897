.cta-contact-form {
    &__spacer-sm {
        @include media-breakpoint-down(sm) {
            margin-bottom: $sizer-xxl;
        }
    }

    &__input-meters {
        transition: unset;
        background-color: map-deep-get($colors, "white", "default");
        border-color: map-deep-get($colors, "brand", "primary-lighter");
        color: map-deep-get($colors, "black", "default");
        font-weight: $font-weight-bold;
        font-size: rem-calc(24);
        padding: 0 16px;
        appearance: textfield;

        &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
            appearance: none;
            margin: 0;
        }

        & + .form__label--compact {
            transition: unset;
            right: 16px;
            left: auto;
            color: map-deep-get($colors, "brand", "primary-dark");
        }

        &:focus,
        &[data-state="filled"] {
            + .form__label--compact {
                right: 16px;
                top: 13px;
                left: unset;
                font-size: rem-calc(16);
            }
        }

        &:-webkit-autofill {
            + .form__label--compact {
                right: 16px;
                top: 13px;
                left: unset;
                font-size: rem-calc(16);
            }
        }
    }
}
