.usp {
    background: map-deep-get($colors, "brand", "secondary");
    position: relative;
    color: map-deep-get($colors, "white", "default");
    padding-top: 1.5rem;
    z-index: 1;
    padding-bottom: 1.5rem;

    &__shape-top {
        position: absolute;
        bottom: 100%;
    }

    &__shape-bottom {
        position: absolute;
        top: 100%;
    }

    /* stylelint-disable */
    h3 {
        text-transform: uppercase;
        font-size: 1.5rem;
        font-weight: $font-weight-normal;
        letter-spacing: .1em;
    }
    /* stylelint-enable */
}
