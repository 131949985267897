.illustration {
    @include media-breakpoint-up(md) {
        position: absolute;
        right: 5%;
        transform: translateY(-65%);

        &--block {
            position: relative;
        }
    }

    &__product {
        @media (min-width: 1400px) {
            max-width: 85%;
            margin-bottom: -100px;
        }

        @media (min-width: 1920px) {
            max-width: 75%;
            margin-bottom: -100px;
        }

        @media (min-width: 2560px) {
            max-width: 50%;
            margin-bottom: -100px;
        }
    }

    &__line {
        position: absolute;
        filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.25));
        top: 0;
        left: 12.5%;
        bottom: 50%;
        width: 2px;
        background: map-deep-get($colors, "white", "default");
        transform: translateX(-50%);

        svg {
            display: none;
        }

        @include media-breakpoint-up(md) {
            background: none;
            width: inherit;
            top: 50%;
            bottom: 12.5%;
            left: 10%;
            right: 50%;
            transform: scaleY(-1);

            svg {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
    }

    &__dot {
        position: absolute;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        border: 0 solid map-deep-get($colors, "brand", "secondary");
        background-color: map-deep-get($colors, "brand", "secondary");
        height: .5rem;
        width: .5rem;
        top: 50%;
        left: 12.5%;
        filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.25));

        @include media-breakpoint-up(md) {
            left: 50%;
            top: 50%;
        }

        &::before {
            content: "";
            border-radius: 50%;
            border: 2px solid #ffffff;
            background-color: transparent;
            height: 1.25rem;
            width: 1.25rem;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    /* stylelint-disable */
    img {
        width: 100%;
    }
    /* stylelint-enable */
}
