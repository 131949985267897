.shape {
    height: 2.5rem;
    width: 100%;
    margin-top: -1px;
    position: relative;
    text-align: center;
    $elm: &;

    @include media-breakpoint-up(sm) {
        height: 5rem;
    }

    @include media-breakpoint-up(md) {
        height: 7.5rem;
    }

    @include media-breakpoint-up(xl) {
        height: 10rem;
    }

    &__inner {
        height: 100%;
        overflow: hidden;

        &::after {
            content: "";
            height: 100%;
            top: 0;
            left: 0;
            width: 30%;
            display: block;
            background: map-deep-get($colors, "brand", "secondary");
        }

        svg {
            fill: map-deep-get($colors, "brand", "secondary");
            height: 100%;
            width: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &--small {
        height: 2.5rem;

        #{$elm}__inner {
            &::after {
                width: 40%;
            }
        }

        @include media-breakpoint-up(md) {
            height: 3.75rem;
        }

        @include media-breakpoint-up(xl) {
            height: 5rem;
        }
    }

    &--text {
        #{$elm}__inner {
            &::after {
                width: 50%;
            }

            svg {
                left: 66.667%;
            }
        }
    }

    &--absolute-top {
        top: 0;
        position: absolute;
    }

    &--product {
        #{$elm}__inner {
            &::after {
                width: 25%;
            }

            svg {
                left: 33.333%;
            }
        }
    }

    &--flip-y {
        transform: scaleY(-1);
        margin-top: 0;
        margin-bottom: -1px;
    }

    &--flip-x {
        transform: scaleX(-1);
    }

    &--flip {
        transform: scale(-1);
        margin-top: 0;
        margin-bottom: -1px;
    }

    &--hero {
        display: none;
        transform: translateX(-50%) scaleY(-1);
        margin-top: 0;
        margin-bottom: -1px;

        @include media-breakpoint-up(md) {
            position: absolute;
            display: block;
        }

        #{$elm}__inner {
            &::after {
                right: 66.667%;
                width: 100vw;
                left: auto;
                position: absolute;
            }
        }
    }

    &--white {
        #{$elm}__inner {
            &::after {
                background: map-deep-get($colors, "white", "default");
            }

            svg {
                fill: map-deep-get($colors, "white", "default");
            }
        }
    }

    &--dark-blue {
        #{$elm}__inner {
            &::after {
                background: map-deep-get($colors, "brand", "primary-dark");
            }

            svg {
                fill: map-deep-get($colors, "brand", "primary-dark");
            }
        }
    }

    &--blue {
        #{$elm}__inner {
            &::after {
                background: map-deep-get($colors, "brand", "primary");
            }

            svg {
                fill: map-deep-get($colors, "brand", "primary");
            }
        }
    }
}
