.switcher {
    position: relative;
    height: 40px;
    border-radius: 20px;
    display: inline-flex;
    flex-shrink: 0;
    box-shadow: 0 0 0 1px inset map-deep-get($colors, "brand", "primary");
    width: fit-content;
    overflow: hidden;

    @include media-breakpoint-down(sm) {
        margin-top: 20px;
    }

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 50%;
        border-radius: 20px;
        background-color: map-deep-get($colors, "brand", "primary");
        transition: left 0.3s 0s ease, width 0.3s 0.3s ease;
        border: 2px solid map-deep-get($colors, "brand", "primary");
    }

    &.right::before {
        left: 50%;
        width: 100%;
        transition: left 0.3s 0.3s ease, width 0.3s 0s ease;
    }

    @include media-breakpoint-down(sm) {
        margin-bottom: $sizer-md;
    }

    &--right-center {
        display: inline-flex;
        justify-self: flex-end;
    }

    &__button {
        position: relative;
        font-weight: $font-weight-bold;
        min-width: 78px;
        border: 0;
        border-radius: 20px;
        padding: 0 $sizer-md;
        background-color: transparent;
        color: map-deep-get($colors, "brand", "primary");

        &:disabled {
            color: map-deep-get($colors, "white", "default");
        }

        &:focus {
            outline: 0;
        }
    }
}
