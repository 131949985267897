.contact {
    color: map-deep-get($colors, "brand", "primary");

    &__prefix {
        width: 50px;
        font-weight: $font-weight-bold;
        flex-shrink: 0;

        @include media-breakpoint-down(md) {
            width: 80px;
        }
    }

    &__row {
        display: flex;
        flex-direction: row;
        margin-bottom: $sizer-md;

        &--condensed {
            margin-bottom: $sizer-md / 2;
        }

        /* stylelint-disable */
        svg {
            color: map-deep-get($colors, "brand", "primary");
            margin-right: 5px;
            path {
                fill: map-deep-get($colors, "brand", "primary");
            }
        }
        /* stylelint-enable */
    }

    &__address {
        &-primary {
            margin-bottom: $sizer-xxl;
        }

        &-secundary {
            margin-bottom: $sizer-xxl;
        }
    }
}
