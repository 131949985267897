.search-result {
    border-bottom: 1px solid rgba(map-deep-get($colors, "brand", "primary"), 0.2);
    padding: $sizer-xl 0;

    &:last-of-type {
        border-bottom: 0;
    }

    &__tag {
        font-family: $font-family-eurostile;
        font-size: 14px;
        //line-height: 100%;

        /* identical to box height, or 14px */
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: map-deep-get($colors, "brand", "primary");
        font-weight: 900;
    }

    &__description {
        padding-bottom: $sizer-lg;
    }
}
