.gap {
    gap: $gap-default;

    &--horizontal {
        gap: 0 $gap-default;
    }

    &--vertical {
        gap: $gap-default 0;
    }

    &--small {
        gap: $gap-small;
    }

    &--small-horizontal {
        gap: 0 $gap-small;
    }

    &--small-vertical {
        gap: $gap-small 0;
    }

    &--large {
        gap: $gap-large;
    }

    &--large-horizontal {
        gap: 0 $gap-large;
    }

    &--large-vertical {
        gap: $gap-large 0;
    }
}
