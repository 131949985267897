.page-header {
    position: relative;
    $elm: &;

    &--noimg {
        height: 33.333vw;
        background: map-deep-get($colors, "brand", "primary");

        @include media-breakpoint-up(sm) {
            height: 20vw;
        }
    }

    &__img {
        height: 50vw;
        background-size: cover;
        background-position: center;

        @include media-breakpoint-up(sm) {
            height: 33.333vw;
        }

        @include media-breakpoint-up(md) {
            height: 25vw;
        }
    }

    &__shape {
        position: absolute;
        left: 0;
        top: 100%;
        transform: translateY(-100%) scaleY(-1);
    }
}
