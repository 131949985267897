.button {
    cursor: pointer;
    letter-spacing: 0.05em;
    font-family: $font-family-eurostile;
    padding: 1rem 2rem;
    min-height: 3rem;
    line-height: 1.5rem;
    font-size: 1.125rem;
    border-radius: $border-radius;
    background-color: map-deep-get($colors, "gray", "dark");
    color: map-deep-get($colors, "white", "default");
    border: 1px solid transparent;
    transition: $transition-default;
    font-weight: 800;
    text-align: center;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    margin: 0 .5rem .5rem 0;

    &:hover {
        transform: scale(1.025);
        color: map-deep-get($colors, "white", "default");
        box-shadow: $shadow-hover;
        text-decoration: none;
    }

    svg {
        width: 1.5rem;
        height: 1.5rem;
        fill: map-deep-get($colors, "white", "default");
    }

    &--search {
        font-size: rem-calc(18);
        letter-spacing: 0.05em;

        @include media-breakpoint-down(xs) {
            width: 100%;
            justify-content: center;
        }
    }

    &--icon {
        height: 2.5rem;
        width: 2.5rem;
        min-height: 0;
        padding: 0;
        justify-content: center;

        svg {
            margin: 0;
            height: 1.25rem;
            width: 1.25rem;
        }
    }

    &--icon-left {
        svg {
            margin-left: -.25em;
            margin-right: 1em;
        }
    }

    &--elevate {
        box-shadow: $shadow-default;
    }

    &--wide {
        width: 100%;
        justify-content: center;
    }

    &--icon-right {
        svg {
            margin-right: -.25em;
            margin-left: 1em;
        }
    }

    &--primary {
        background-color: map-deep-get($colors, "brand", "primary");
    }

    &--secondary {
        background-color: map-deep-get($colors, "brand", "secondary");
    }

    &--white {
        background: map-deep-get($colors, "white", "default");
        color: map-deep-get($colors, "brand", "primary");

        svg {
            fill: map-deep-get($colors, "brand", "primary");
        }

        &:hover {
            color: map-deep-get($colors, "brand", "primary");
        }
    }

    &--display-block {
        display: block;
    }
}
