.language-select {
    $elm: &;
    font-family: $font-family-eurostile;
    margin-right: $sizer-xl;
    display: flex;
    align-self: flex-end;
    color: map-deep-get($colors, "white", "default");
    user-select: none;

    @include media-breakpoint-down(md) {
        margin-right: 0;
    }

    &__label {
        display: inline-flex;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        align-items: center;
        height: 100%;
        font-size: 14px;
        line-height: 44px;
    }

    &__text {
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    &__dropdown{
        margin-left: $sizer-xs;

        /* stylelint-disable */
        svg {
            margin-right: $sizer-xs;

            path {
                fill: map-deep-get($colors, "white", "default");
            }
        }
        /* stylelint-enable */
    }

    &__segment {
        text-transform: uppercase;
    }

    &__link {
        display: block;
        color: map-deep-get($colors, "white", "default");

        &--selected {
            color: map-deep-get($colors, "brand", "primary-dark");
        }

        &:hover {
            color: map-deep-get($colors, "gray", "light");
        }
    }

    &__sub {
        display: block;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        top: 100%;
        margin: $sizer-md;
        right: 0;
        background-color: map-deep-get($colors, "brand", "primary");
        padding: $sizer-md;
        white-space: nowrap;
        box-shadow: 0 8px 24px rgba(0, 0, 0, 0.16);
        border-radius: 4px;
        transform: translateY(-3rem);
        transition: all 0.5s;
        z-index: 200;
    }

    &--open {
        #{$elm}__sub {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
        }

        #{$elm}__dropdown {
            /* stylelint-disable */
            svg {
                transform: rotate(180deg);
            }
            /* stylelint-enable */
        }
    }
}
