.specification-card {
    background-color: map_deep-get($colors, "brand", "primary");
    padding: rem-calc(40);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.16);
    border-radius: $border-radius;
    margin-bottom: rem-calc(22);
    color: map-deep-get($colors, "white", "default");
    font-family: $font-family-eurostile;

    @include media-breakpoint-down(sm) {
        padding: rem-calc(20);
    }

    &--has-image {
        padding-bottom: 15rem;
    }

    &__image {
        max-width: 120%;
        margin-left: -10%;
        height: auto;
        position: relative;
        z-index: 3;
        margin-top: -50%;

        @include media-breakpoint-up(md) {
            max-width: 130%;
            margin-left: -15%;
        }
    }

    &__row {
        margin: 0;
        border-bottom: 1px solid map-deep-get($colors, "brand", "primary-medium");

        &:last-of-type {
            border-bottom: 0;
        }
    }

    &__property {
        font-weight: 900;
    }

    &__property, &__value {
        padding: $sizer-sm 0;
        vertical-align: middle;
    }

    &__container {
        margin-top: -$sizer-section / 4;

        @include media-breakpoint-up(md) {
            margin-top: -$sizer-section / 2;
        }
        @include media-breakpoint-down(sm) {
            margin-top: 0;
        }
    }
}
