.page {
    &__title {
        &--primary {
            color: map-deep-get($colors, "brand", "primary");
        }

        &--primary-dark {
            color: map-deep-get($colors, "brand", "primary-dark");
        }

        &--center {
            text-align: center;
        }
    }
    /* stylelint-disable */
    &--404 {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: $sizer-section-xl;
    }
    /* stylelint-enable */

    &__filter-bar {
        display: flex;
        flex-direction: row;
        gap: $sizer-sm;
        justify-content: space-between;
        margin-bottom: $sizer-xl;

        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }
    }
}
