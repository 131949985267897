/* stylelint-disable */
.fetchimage {
    &-wrapper {
        position: relative;
        height: 100%;
    }

    &-click {
        &.button {
            top: auto !important;
            bottom: 20px !important;
        }
    }
}
/* stylelint-enable */