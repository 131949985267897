﻿/// <reference path="../_reference.scss" />

*, *::before, *::after {
    box-sizing: border-box;
}

*:focus {
    outline: none;
}

html, body {
    margin: 0 auto;
    padding: 0;
    font-family: $font-family-base;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

a {
    text-decoration: none;
}

b, strong {
    font-weight: 700;
}

::selection {
    background-color: map-deep-get($colors, "brand", "primary");
    color: map-deep-get($colors, "white", "default");
}

.h-100 {
    height: 100%;
}

@include media-breakpoint-up(md) {
    .h-md-auto {
        height: auto;
    }
}