/* stylelint-disable */
@font-face {
    font-family: Roboto;
    src: url("https://use.typekit.net/af/5e9c68/00000000000000003b9ad05f/27/l?primer=fff1a989570eb474b8c22c57cc7199e63bfc7e911b750165d0199218f0b7e7cc&fvd=n7&v=3") format("woff2"),
    url("https://use.typekit.net/af/5e9c68/00000000000000003b9ad05f/27/d?primer=fff1a989570eb474b8c22c57cc7199e63bfc7e911b750165d0199218f0b7e7cc&fvd=n7&v=3") format("woff"),
    url("https://use.typekit.net/af/5e9c68/00000000000000003b9ad05f/27/a?primer=fff1a989570eb474b8c22c57cc7199e63bfc7e911b750165d0199218f0b7e7cc&fvd=n7&v=3") format("opentype");
    font-display: swap;
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: Roboto;
    src: url("https://use.typekit.net/af/e01918/00000000000000003b9ad060/27/l?primer=fff1a989570eb474b8c22c57cc7199e63bfc7e911b750165d0199218f0b7e7cc&fvd=i7&v=3") format("woff2"),
    url("https://use.typekit.net/af/e01918/00000000000000003b9ad060/27/d?primer=fff1a989570eb474b8c22c57cc7199e63bfc7e911b750165d0199218f0b7e7cc&fvd=i7&v=3") format("woff"),
    url("https://use.typekit.net/af/e01918/00000000000000003b9ad060/27/a?primer=fff1a989570eb474b8c22c57cc7199e63bfc7e911b750165d0199218f0b7e7cc&fvd=i7&v=3") format("opentype");
    font-display: swap;
    font-style: italic;
    font-weight: 700;
}

@font-face {
    font-family: Roboto;
    src: url("https://use.typekit.net/af/5be8cf/00000000000000003b9ad059/27/l?primer=fff1a989570eb474b8c22c57cc7199e63bfc7e911b750165d0199218f0b7e7cc&fvd=i4&v=3") format("woff2"),
    url("https://use.typekit.net/af/5be8cf/00000000000000003b9ad059/27/d?primer=fff1a989570eb474b8c22c57cc7199e63bfc7e911b750165d0199218f0b7e7cc&fvd=i4&v=3") format("woff"),
    url("https://use.typekit.net/af/5be8cf/00000000000000003b9ad059/27/a?primer=fff1a989570eb474b8c22c57cc7199e63bfc7e911b750165d0199218f0b7e7cc&fvd=i4&v=3") format("opentype");
    font-display: swap;
    font-style: italic;
    font-weight: 400;
}

@font-face {
    font-family: Roboto;
    src: url("https://use.typekit.net/af/acc12d/00000000000000003b9ad05b/27/l?primer=fff1a989570eb474b8c22c57cc7199e63bfc7e911b750165d0199218f0b7e7cc&fvd=n4&v=3") format("woff2"),
    url("https://use.typekit.net/af/acc12d/00000000000000003b9ad05b/27/d?primer=fff1a989570eb474b8c22c57cc7199e63bfc7e911b750165d0199218f0b7e7cc&fvd=n4&v=3") format("woff"),
    url("https://use.typekit.net/af/acc12d/00000000000000003b9ad05b/27/a?primer=fff1a989570eb474b8c22c57cc7199e63bfc7e911b750165d0199218f0b7e7cc&fvd=n4&v=3") format("opentype");
    font-display: swap;
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: Eurostile;
    src: url("https://use.typekit.net/af/2d8a3b/00000000000000003b9b2159/27/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n4&v=3") format("woff2"),
    url("https://use.typekit.net/af/2d8a3b/00000000000000003b9b2159/27/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n4&v=3") format("woff"),
    url("https://use.typekit.net/af/2d8a3b/00000000000000003b9b2159/27/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n4&v=3") format("opentype");
    font-display: swap;
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: Eurostile;
    src: url("https://use.typekit.net/af/c6e0fb/00000000000000003b9b2160/27/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n5&v=3") format("woff2"),
    url("https://use.typekit.net/af/c6e0fb/00000000000000003b9b2160/27/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n5&v=3") format("woff"),
    url("https://use.typekit.net/af/c6e0fb/00000000000000003b9b2160/27/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n5&v=3") format("opentype");
    font-display: swap;
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: Eurostile;
    src: url("https://use.typekit.net/af/6be0f8/00000000000000003b9b2163/27/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n7&v=3") format("woff2"),
    url("https://use.typekit.net/af/6be0f8/00000000000000003b9b2163/27/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n7&v=3") format("woff"),
    url("https://use.typekit.net/af/6be0f8/00000000000000003b9b2163/27/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n7&v=3") format("opentype");
    font-display: swap;
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: Eurostile;
    src: url("https://use.typekit.net/af/741689/00000000000000003b9b2165/27/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n8&v=3") format("woff2"),
    url("https://use.typekit.net/af/741689/00000000000000003b9b2165/27/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n8&v=3") format("woff"),
    url("https://use.typekit.net/af/741689/00000000000000003b9b2165/27/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n8&v=3") format("opentype");
    font-display: swap;
    font-style: normal;
    font-weight: 800;
}
/* stylelint-enable */