.section-padding {
    padding-top: 2.25rem;
    padding-bottom: 1.25rem;

    @include media-breakpoint-up(sm) {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
    }

    @include media-breakpoint-up(md) {
        padding-top: 3.75rem;
        padding-bottom: 3.75rem;
    }

    @include media-breakpoint-up(xl) {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }
}
