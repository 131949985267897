.project-map__container {
    width: 100%;
    //max-width: 400px;
    height: 300px;
    margin: 20px 0;

    .vue-map {
        border-radius: 10px;
    }

    @include media-breakpoint-down(sm) {
        height: 400px;
    }
}
