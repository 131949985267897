.footer {
    background-color: map-deep-get($colors, "white", "default");
    color: map-deep-get($colors, "brand", "primary");
    position: relative;
    z-index: 15;
    flex: 1 1 100%;

    a:not(.button) {
        color: map-deep-get($colors, "black", "default");
    }

    &__bottom {
        padding-top: 30px;
        padding-bottom: 30px;

        @include media-breakpoint-up(md) {
            padding-top: 40px;
            padding-bottom: 40px;
        }

        /* stylelint-disable */
        a:not(.button) {
            color: map-deep-get($colors, "brand", "primary");
            text-decoration: none;
            font-family: $font-family-eurostile;
            font-size: rem-calc(16);
            line-height: 150%;
            font-weight: 500;

            &:hover {
                text-decoration: underline;
            }
        }

        strong,
        b {
            color: map-deep-get($colors, "brand", "secondary");
            font-family: $font-family-eurostile;
            font-size: rem-calc(18);
        }

        p {
            margin-bottom: $sizer-md;
            margin-block-start: 0;
        }

        p:first-of-type {
            margin-bottom: $sizer-sm;
            margin-block-end: $sizer-sm;
        }
    }

    &__brand-links {
        a:not(.button) {
            color: map-deep-get($colors, "brand", "primary-light");
            opacity: 0.5;

            &:hover {
                opacity: 1;
            }
        }
        /* stylelint-enable */
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
    }

    &__list-item {
        width: 100%;
        flex: 0 0 100%;
        text-align: center;
        font-size: 14px;
        font-weight: 100;
        color: map-deep-get($colors, "black", "default");
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        margin-bottom: 0.5em;

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
            display: inline-flex;
            width: auto;
            flex: 0 0 auto;
            text-align: left;
            padding-right: 10px;
            align-items: center;

            &::after {
                content: "";
                width: 0.5em;
                height: 0.5em;
                background: map-deep-get($colors, "black", "default");
                border-radius: 50%;
                display: inline-block;
                vertical-align: middle;
                margin-left: 10px;
            }

            &:last-child::after {
                content: none;
            }
        }
    }

    &__item {
        color: inherit;

        &:hover {
            text-decoration: underline;
        }
    }

    &__reference {
        font-size: 14px;
        font-weight: 100;
        color: map-deep-get($colors, "black", "default");
        -webkit-font-smoothing: antialiased;
        display: block;
        text-align: center;
        width: 100%;

        @include media-breakpoint-up(md) {
            display: inline-block;
            text-align: left;
        }
    }

    &__webnl, &__group {
        transition: $transition-default;
        color: inherit;

        &:hover {
            text-decoration: underline;
        }
    }
}
