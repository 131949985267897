.slider-wrapper {
    position: relative;

    &--products {
        /* stylelint-disable */
        .slider {
            &__shape {
                .shape__inner {
                    svg {
                        fill: map-deep-get($colors, "brand", "primary");
                    }

                    &::after {
                        background: map-deep-get($colors, "brand", "primary");
                    }
                }
            }
        }
        /* stylelint-enable */
    }

    /* stylelint-disable */
    img {
        width: 100%;
        height: auto;
    }
    /* stylelint-enable */
}
