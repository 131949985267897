.subbar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: map_get($header-height, "large");
    transition: $transition-default;

    &__navigation {
        max-width: 100%;
        transition: max-width 0.3s ease-in;

        &--searching {
            max-width: rem-calc(810);
        }
    }

    &__mobile {
        display: none;
        @include media-breakpoint-down(md) {
            display: flex;
        }
    }

    &--expanded {
        background: map-deep-get($colors, "white", "default");
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
    }

    &__hamburger {
        display: none;
        padding-left: 32px;
    }

    &__logo {
        transition: $transition-default;
        height: 100%;
        padding: rem-calc(16) 0  rem-calc(16) rem-calc(32);
        display: flex;
        z-index: 9;

        @include media-breakpoint-down(md) {
            padding: rem-calc(16);
        }
        /* stylelint-disable */
        svg {
            width: 100%;
            height: auto;
        }
        /* stylelint-enable */
    }

    &__logo-image {
        height: 100%;
        width: 100%;
        align-self: center;
    }

    &__menu-toggle {
        float: right;
        position: relative;
    }

    @include media-breakpoint-down(md){
        &__hamburger {
            display: flex;
            padding-left: 10px;
        }

        &__navigation {
            display: none;
        }
    }
}
