.si-float-wrapper {
    position: absolute;
    width: 100%;
}

.si-float-wrapper,
.si-float-wrapper * {
    box-sizing: border-box;
}

.si-wrapper-top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 14px;
    cursor: default;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-top: -40px;
    margin-left: 0px;
    -webkit-transform: translate(-50%, -100%);
    transform: translate(-50%, -100%);
}

.si-shadow-wrapper-top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.29804;
    z-index: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.si-frame {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    //overflow: hidden;
    z-index: 2;
}

.si-content-wrapper {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
}

.si-content {
    //overflow: auto;
}
