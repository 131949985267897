.site-select {
    $elm: &;
    margin-left: 2rem;

    @include media-breakpoint-down(md) {
        margin-left: 0;
    }

    &__label {
        display: inline-flex;
        align-items: center;
        font-family: $font-family-eurostile;
        font-size: .875rem;
        line-height: 2.75rem;
        height: 100%;
        text-align: right;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: map-deep-get($colors, "white", "default");

        &::before {
            content: " ";
            height: 20px;
            width: 20px;
            margin-right: 8px;
            background-image: url("/images/icons/site-select/globe.svg");
            background-size: contain;
        }
    }

    &__trigger {
        &:hover {
            #{$elm}__sub {
                display: block;
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
            }
        }
    }

    &__sub {
        position: absolute;
        opacity: 0;
        visibility: hidden;
        top: 100%;
        transform: translateY(-3rem);
        transition: all 0.5s;
    }

    &__group {
        margin-top: 24px;

        &:first-of-type {
            margin-top: 0;
        }
    }

    &__list--sub {
        background-color: map-deep-get($colors, "brand", "primary");
        min-width: rem-calc(208);
        margin-top: rem-calc(8);
        padding: $sizer-md;
        box-shadow: $shadow-default;
        border-radius: $sizer-xs;
        font-family: $font-family-eurostile;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.05em;

        /* stylelint-disable */
        strong {
            color: map-deep-get($colors, "brand", "primary-dark");
        }

        a {
            color: map-deep-get($colors, "white", "default");
        }
        /* stylelint-enable */
    }
}
