.sharer {
    position: relative;
    z-index: 30;

    @include media-breakpoint-down(sm) {
        margin-bottom: $sizer-xxl;
    }

    &__wrapper {
        z-index: 30;
        align-items: center;

        @include media-breakpoint-up(md) {
            position: absolute;
            top: $section-spacer-height;
            transform: translateY(-50%);
        }

        @include media-breakpoint-down(sm) {
            margin-top: $sizer-xl;
        }
    }

    &__label {
        letter-spacing: 0.1em;
        text-transform: uppercase;
        line-height: 100%;
        color: map-deep-get($colors, "brand", "primary");
        margin-right: $sizer-md;
        margin-bottom: 0;
    }

    &__button {
        margin-right: $sizer-sm;
    }
}
