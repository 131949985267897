.parallax-image__aspect-ratio-wrap {
    position: relative;
    top: -50%;
    height: 0;
    overflow: hidden;
}

.parallax-image__aspect-ratio-inside {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* stylelint-disable */
    img {
        width: 100%;
    }
    /* stylelint-enable */
}

.parallax-container {
    overflow: hidden;
}

.parallax-mouse-container {
    overflow: hidden;
    position: relative;
    top: 0;
}

.parallax-layer {
    position: absolute;
}

.parallax-layer img {
    width: 100%;
    margin-top: -50px;
}
