.news-card {
    margin-bottom: 1.5rem;

    &__image {
        width: 100%;
        box-shadow: $shadow-default;
        border-radius: 4px;
    }

    &__date {
        opacity: 0.5;
        color: map-deep-get($colors, "brand", "primary");
        font-family: $font-family-eurostile;
        margin-bottom: .5;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        font-size: .875rem;
        font-weight: 700;
    }

    &__content {
        margin-top: auto;
        padding: $sizer-lg;
        bottom: 0;
    }

    &__description {
        padding-bottom: $sizer-lg;
    }
}
