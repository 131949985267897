.offices {
    background: map-deep-get($colors, "brand", "primary");
    color: map-deep-get($colors, "white", "default");
    padding-bottom: 2.5rem;
    position: relative;

    @include media-breakpoint-up(md) {
        padding-bottom: 5rem;
    }

    @include media-breakpoint-up(xl) {
        padding-bottom: 7.5rem;
    }

    &__global {
        z-index: 0;
        position: relative;
        margin-top: -1.25rem;

        @include media-breakpoint-up(md) {
            margin-top: initial;
        }
    }
}
