$section-spacer-height: $sizer-section;
$section-spacer-height-sm: $section-spacer-height / 2;
$section-padding-y: $sizer-section;
$section-padding-y-sm: $section-padding-y / 2;

.section {
    $elm: &;
    position: relative;
    width: 100%;
    color: map-deep-get($colors, "black", "default");
    min-height: $sizer-section;
    padding: $section-padding-y 0;

    @include media-breakpoint-down(sm) {
        padding: $section-padding-y-sm 0;
    }

    &--no-min-height {
        min-height: 0;
    }

    &__title {
        position: relative;
        margin-bottom: rem-calc(40);
        margin-top: 0;
        font-size: rem-calc(32);
        letter-spacing: 0.1em;
        text-transform: uppercase;
        font-weight: 700;

        &--accolade {
            &::before {
                position: absolute;
                margin-left: rem-calc(-26);
                margin-top: rem-calc(-1);
                opacity: 0.3;
                content: "";
                background-image: url("/images/icons/accolade-primary.svg");
                background-size: contain;
                background-repeat: no-repeat;
                height: rem-calc(40);
                width: rem-calc(10.8);
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
            }
        }
    }

    &--gradient-blue {
        background: $gradient-default;
        color: map-deep-get($colors, "white", "default");

        /* stylelint-disable */
        .rich-text-area {
            ul {
                li {
                    &::before {
                        background: map-deep-get($colors, "brand", "secondary");
                    }
                }
            }
        }
        /* stylelint-enable */

        #{$elm}__title::before {
            background-image: url("/images/icons/accolade.svg");
        }
    }

    &--primary {
        background-color: map-deep-get($colors, "brand", "primary");
        color: map-deep-get($colors, "white", "default");

        #{$elm}__title::before {
            background-image: url("/images/icons/accolade.svg");
        }
    }

    &--primary-dark {
        background-color: map-deep-get($colors, "brand", "primary-dark");
        color: map-deep-get($colors, "white", "default");

        #{$elm}__title::before {
            background-image: url("/images/icons/accolade.svg");
        }
    }

    &--no-padding {
        padding: 0;
    }

    &--no-padding-top {
        padding-top: 0;

        @include media-breakpoint-down(sm) {
            padding-top: 2.5rem;
        }
    }

    &--no-padding-bottom {
        padding-bottom: 0;
    }

    &--padding-bottom-half {
        padding-bottom: $section-padding-y-sm;
    }

    &--padding-bottom-2x {
        padding-bottom: $section-padding-y * 2;
        @include media-breakpoint-down(sm) {
            padding-bottom: $section-padding-y-sm * 2;
        }
    }

    &--padding-sm {
        @include media-breakpoint-down(sm) {
            padding: $section-padding-y-sm 0;
        }
    }

    &--secondary {
        background-color: map-deep-get($colors, "brand", "secondary");
        color: map-deep-get($colors, "white", "default");
    }

    &--white {
        background-color: map-deep-get($colors, "white", "default");
        color: map-deep-get($colors, "black", "default");
    }

    &--move-up {
        margin-top: -$section-spacer-height;
        @include media-breakpoint-down(sm) {
            margin-top: -$section-spacer-height-sm;
        }
    }

    &--z-index {
        z-index: 1;
    }
}
