.paragraph {
    margin-bottom: rem-calc(32);

    &--intro {
        line-height: 1.25;
        font-family: $font-family-eurostile;

        @include media-breakpoint-up(sm) {
            font-size: 1.125rem;
        }

        @include media-breakpoint-up(md) {
            font-size: 1.5rem;
        }
    }

    &--margin-90 {
        margin-bottom: rem-calc(90);
    }
}
