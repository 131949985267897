.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 99;

    &__bar {
        position: relative;
        z-index: 2;
    }

    &__gradient {
        pointer-events: none;

        &::after {
            content: "";
            width: 100vw;
            height: 100vh;
            pointer-events: none;
            position: absolute;
            z-index: 1;
            top: 44px;
            opacity: 0;
            background: linear-gradient(180deg, rgba(0, 51, 106, 0.5) 0%, rgba(0, 97, 161, 0) 100%);
            transition: 0.3s ease;
        }

        &::before {
            content: "";
            width: 100vw;
            height: rem-calc(120);
            position: absolute;
            left: 0;
            top: 0;
            background: linear-gradient(180deg, rgba(0, 51, 106, 0.75) 0%, rgba(0, 97, 161, 0) 100%);
            pointer-events: none;
            z-index: 1;
        }

        &--full {
            &::after {
                height: 100vh;
                opacity: 1;
            }
        }

        &--navigation {
            background: linear-gradient(247.98deg, #00336a 0%, rgba(0, 97, 161, 0) 100%);
            opacity: 1;
        }

        &--site-select {
            background: linear-gradient(131.99deg, #00336a 0%, rgba(0, 97, 161, 0) 100%);
            opacity: 1;
        }

        &--language {
            background: linear-gradient(247.98deg, #00336a 0%, rgba(0, 97, 161, 0) 100%);
            opacity: 1;
        }
    }
}

.cta-block {
    height: 100%;

    padding: 0 0;

    &__icon {

        @include media-breakpoint-up(md) {
            margin-right: 10px;
        }

        @include media-breakpoint-down(md) {
            padding: 0 10px;
        }

        svg {
            height:20px;
            fill: map-deep-get($colors, "white", "default");
        }
    }

    &__title {
        color: white;
        line-height: 43px;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }
}

.cta-block-call {
    justify-content: flex-end;
    height: 100%;
    background: #0061a1;
    display: flex;
    line-height:40px;

    @include media-breakpoint-up(md) {
        padding: 0 30px
    }
}

.cta-block-quotation {
    justify-content: flex-end;
    height: 100%;
    background: #e42313;
    display: flex;
    line-height:40px;

    @include media-breakpoint-up(md) {
        padding: 0 30px
    }
}
