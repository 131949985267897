$logo-width: 363px;
$logo-correction: calc(#{$logo-width} / 2 + 29px);

/* stylelint-disable */
.site.portal {
    .main {
        padding-top: 0;
    }

    .header__gradient {
        display: none;
    }

    .logo {
        position: absolute;
        height: 3rem;
        z-index: 3;
        top: calc(44px + 2rem);
        right: 0;
        transform: translateX(44%);

        svg {
            height: 100%;
            width: auto;
        }
    }
}
/* stylelint-enable */
