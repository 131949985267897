.product-card {
    display: block;
    border-radius: $border-radius;
    margin-bottom: 20px;
    transition: $transition-default;
    $elm: &;

    @include media-breakpoint-down(xs) {
        padding-top: 75%;
    }

    &__image {
        width: 100%;
        height: auto;
        transition: $transition-default;
    }

    &__content {
        flex-direction: column;
        text-align: center;
        padding: $sizer-xl;
    }

    &__title {
        flex-grow: 1;

        @include media-breakpoint-down(md) {
            font-size: rem-calc(16);
        }
        @include media-breakpoint-down(sm) {
            font-size: rem-calc(24);
        }
    }

    &__link {
        opacity: 0;
    }

    &__links {
        transform: translateY(1rem);
        transition: $transition-default;
    }

    &:hover {
        box-shadow: $shadow-hover;
        background-color: map-deep-get($colors, "brand", "primary-dark");
        transform: scale(1.05);

        #{$elm}__link {
            opacity: 1;
        }

        #{$elm}__links {
            transform: translateY(0);
        }

        #{$elm}__image {
            opacity: 0.25;
        }
    }
}
