.projects-infowindow {
    width: 320px;
    background-color: map-deep-get($colors, "white", "default");
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.16);
    border-radius: 5px;

    &__inner {
        border-radius: 5px;
        overflow: hidden;
    }

    &__close {
        position: absolute;
        z-index: 3;
        top: 5px;
        right: 5px;
        border: 0;
        background: transparent;

        svg {
            fill: map-deep-get($colors, "white", "default");
        }
    }

    &__image {
        max-width: 100%;
        width: 100%;
    }

    &__gradient {
        position: absolute;
        top: 0;
        left: 0;
        opacity: .5;
        width: 100%;
        height: rem-calc(160);
        background: linear-gradient(236.01deg, #00336a 0%, rgba(0, 97, 161, 0) 100%);
    }

    &__content {
        padding: rem-calc(24);
        font-family: $font-family-eurostile;
    }

    &__title {
        font-size: rem-calc(14);
        margin-bottom: $sizer-md;
        line-height: 100%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: map-deep-get($colors, "brand", "primary");
    }

    &__type {
        font-size: rem-calc(14);
        font-weight: 700;
        margin-bottom: $sizer-md;
        line-height: 100%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: map-deep-get($colors, "brand", "primary");
    }

    &__paragraph {
        color: map-deep-get($colors, "brand", "primary");
        margin-bottom: rem-calc(26);
    }

    &__emblem {
        width: rem-calc(40);
        height: rem-calc(40);
        position: absolute;
        bottom: rem-calc(-20);
        left: 50%;
        margin-left: rem-calc(-20);
    }
}
