.ratio {
    position: relative;
    width: 100%;

    &__content {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        &--black {
            background-color: map-deep-get($colors, "black", "default");
        }
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        font-family: "object-fit: cover;";
    }

    $ratios: (
        "r4-1": 25%,
        "r3-1": 33.333%,
        "r21-9": 42.857%,
        "r2-1": 50%,
        "r16-9": 56.25%,
        "r8-5": 62.5%,
        "r3-2": 66.66%,
        "r4-3": 75%,
        "r1-1": 100%,
    );

    @each $ratio, $percentage in $ratios {
        &--#{$ratio} {
            padding-top: $percentage;
        }
    }
}
