.reference {
    &-card {
        background: #ffffff;
        border-radius: 4px;
        margin: 0px 0px 23px 0px;
        padding: 25px 0;

        img {
            display: block;
            height: 90px;
            margin: 0 auto;
            object-fit: contain;
            object-position: center;
            width: 222px;
        }
    }
}

