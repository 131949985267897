﻿h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-family: $font-family-eurostile;
    margin-bottom: 1rem;
    font-weight: 800;
    line-height: 1;
}

p {
    margin-top: 0;
}

h1, .h1 {
    font-size: 2rem;

    @include media-breakpoint-up(sm) {
        font-size: 2.5rem;
    }

    @include media-breakpoint-up(md) {
        font-size: 2.875rem;
    }

    @include media-breakpoint-up(xl) {
        font-size: 3.75rem;
    }
}

h2, .h2 {
    font-size: 1.75rem;

    @include media-breakpoint-up(sm) {
        font-size: 2rem;
    }

    @include media-breakpoint-up(md) {
        font-size: 2.5rem;
    }
}

h3, .h3 {
    font-size: 1.5rem;

    @include media-breakpoint-up(md) {
        font-size: 2rem;
    }
}

h4, .h4 {
    font-size: 1.25rem;

    @include media-breakpoint-up(md) {
        font-size: 1.5rem;
    }
}

h5, .h5 {
    font-size: 1.125rem;

    @include media-breakpoint-up(md) {
        font-size: 1.25rem;
    }
}

h6, .h6 {
    font-size: 1rem;
}

.intro {
    font-size: 1.25rem;
    line-height: 1.25;
    font-family: $font-family-eurostile;

    @include media-breakpoint-up(md) {
        font-size: 1.5rem;
    }
}

strong {
    font-weight: 900;
}
