.about-team {
    padding: rem-calc(5);
    padding-top: rem-calc(15);

    @include media-breakpoint-up(md) {
        padding-top: rem-calc(80);
        padding-bottom: rem-calc(80);
    }

    &--gradient {
        background: linear-gradient(90deg, map-deep-get($colors, "brand", "primary") 0%, map-deep-get($colors, "brand", "primary-dark") 100%);
    }
}
