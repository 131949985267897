.navigation_search {
    $elm: &;

    &__box {
        display: none;
        margin-right: $sizer-xl;
        width: 0;

        &--show {
            display: flex;
            width: rem-calc(510);

            #{$elm}_input {
                width: rem-calc(510);
            }
        }
    }

    &__form {
        position: relative;
        width: auto;

        @include media-breakpoint-down(md) {
            margin-bottom: $sizer-lg;
        }

        @include media-breakpoint-down(sm) {
            position: static;
            margin-top: 20px;
        }
    }

    /* stylelint-disable */
    &__button {
        display: flex;
        border: 0;
        background: 0;

        &--searching {
            .search {
                opacity: 0;
                width: 0;
            }

            .close {
                transition: opacity 0.5s ease-in;
                opacity: 1;
            }
        }

        &--not-searching {
            .search {
                transition: opacity 0.5s ease-in;
                opacity: 1;
            }

            .close {
                opacity: 0;
                width: 0;
            }
        }

        &:focus {
            outline: 0;
        }

        svg {
            fill: map-deep-get($colors, "brand", "primary");

            @include media-breakpoint-down(md) {
                fill: map-deep-get($colors, "white", "default");
            }
        }

        &--inline {
            position: absolute;
            right: 10px;
            top: 13px;
        }
    }
    /* stylelint-enable */

    &__input {
        display: block;
        width: rem-calc(510);
        border-color: map-deep-get($colors, "gray", "light");
        color: map-deep-get($colors, "black", "default");
        padding-right: 60px;

        &::placeholder {
            color: map-deep-get($colors, "black", "default");
        }

        @include media-breakpoint-down(md) {
            width: 100%;
            color: map-deep-get($colors, "white", "default");

            &::placeholder {
                color: map-deep-get($colors, "white", "default");
            }
        }
    }
}
