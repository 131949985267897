.slider {
    height: 100vw;
    width: 100%;

    @include media-breakpoint-up(sm) {
        height: 75vw;
    }

    @include media-breakpoint-up(lg) {
        height: 56.25vw;
    }

    &__image {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    &__buttons {
        position: absolute;
        top: 100%;
        transform: translate(-50%, -110%);
        left: 50%;
        text-align: left;
        z-index: 2;

        @include media-breakpoint-up(md) {
            transform: translate(-50%, -50%);
        }
    }

    &__button {
        height: rem-calc(50);
        width: rem-calc(50);
        margin-right: $sizer-sm;
    }

    &__shape {
        position: absolute;
        top: 0;
        z-index: 1;
    }
}
