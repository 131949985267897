.cta {
    background-color: map-deep-get($colors, "brand", "primary");
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    padding: $sizer-xl;
    color: map-deep-get($colors, "white", "default");

    &__title {
        margin-top: rem-calc(12);
    }

    &__description {
        margin-bottom: $sizer-lg;
    }

    &__button {
        &--below {
            @include media-breakpoint-up(md) {
                position: absolute;
                bottom: -$sizer-section;
                transform: translateY(50%);
                z-index: 30;
            }
        }
    }

    &--small {
        @include media-breakpoint-up(md) {
            z-index: 30;
            margin-bottom: -$section-padding-y;
        }
    }

    &--padding-bottom-sm {
        padding-bottom: rem-calc(20);
    }

    a:not(.button) {
        color: map-deep-get($colors, "white", "default");
        text-decoration: underline;

        &:hover {
            font-weight: 700;
        }
    }
}
