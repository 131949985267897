
.albumslider {
    width: 100%;
    height: 100%;

    &__wrapper {
        .ratio__content {
            overflow: visible;
        }
        margin-bottom: 50px;
    }

    &__slider {
        width: 100%;
        height: 100%;
    }

    &__slide {
        width: 100%;
        height: 100%;
        text-align: center;
    }

    &__image {
        max-width: 100%;
        max-height: 100%;
    }

    &__buttons {
        position: absolute;
        top: 100%;
        transform: translate(-50%, -50%);
        left: 50%;
        text-align: left;
        z-index: 2;
    }

    &__button {
        height: rem-calc(50);
        width: rem-calc(50);
        margin-right: $sizer-sm;
    }

    &__shape {
        position: absolute;
        top: 0;
        z-index: 1;
    }
}
