.line {
    $innerCircleDiameter: 4;
    $outerCircleDiameter: 10;
    position: absolute;
    top: 0;
    left: 2rem;
    z-index: 500;
    filter: drop-shadow(2px 2px 4px #000000);
    width: 2px;
    border: 1px solid #ffffff;

    &__wrapper {
        position: relative;
        z-index: 3;
    }

    &::after {
        content: "";
        border-radius: 50%;
        border: 0 solid map-deep-get($colors, "brand", "secondary");
        background-color: map-deep-get($colors, "brand", "secondary");
        height: rem-calc($innerCircleDiameter * 2);
        width: rem-calc($innerCircleDiameter * 2);
        position: absolute;
        bottom: 0;
        transform: translate(rem-calc(-$innerCircleDiameter), rem-calc($innerCircleDiameter));
    }

    &::before {
        content: "";
        border-radius: 50%;
        border: 2px solid #ffffff;
        background-color: transparent;
        height: rem-calc($outerCircleDiameter * 2);
        width: rem-calc($outerCircleDiameter * 2);
        position: absolute;
        bottom: 0;
        transform: translate(rem-calc(-$outerCircleDiameter), rem-calc($outerCircleDiameter));
    }

    &--vertical {
        height: calc(30vw + #{$section-padding-y} / 2);
    }

    &--rotate45 {
        transform: translate(10vw, -85%) rotate(60deg);
        min-height: 400px;
        max-height: 30vw;
        //height: max(30vw, 400px);

        @include media-breakpoint-down(lg) {
            transform: translate(0, -80%) rotate(60deg);
            height: calc(30vw + #{$sizer-md} / 2);
        }

        @include media-breakpoint-down(md) {
            transform: translate(30vw, -80%) rotate(-90deg);
            height: calc(25vw + #{$sizer-md} / 2);
        }

        @include media-breakpoint-down(sm) {
            transform: translate(30vw, -80%) rotate(0deg);
            height: calc(25vw + #{$sizer-md} / 2);
        }

        @include media-breakpoint-down(xs) {
            transform: translate(30vw, -80%) rotate(0deg);
            height: calc(25vw + #{$sizer-md} / 2);
        }

        &::before {
            top: 0;
            transform: translate(rem-calc(-$outerCircleDiameter), rem-calc(-$outerCircleDiameter));
        }

        &::after {
            top: 0;
            transform: translate(rem-calc(-$innerCircleDiameter), rem-calc(-$innerCircleDiameter));
        }
    }
}
