.contact-info {
    &--margin-bottom {
        margin-bottom: $sizer-xl * 3;

        @include media-breakpoint-up(lg) {
            &:nth-last-of-type(-n+2) {
                margin-bottom: 0;
            }
        }

        @include media-breakpoint-down(md) {
            margin-bottom: $sizer-xl * 2;
        }

        @include media-breakpoint-down(sm) {
            margin-bottom: $sizer-xl;
        }
    }

    &__title {
        margin-top: 0;
        font-size: rem-calc(24);
        display: block;
        line-height: 100%;
        margin-bottom: $sizer-sm;
    }

    &__paragraph {
        margin-top: rem-calc(8);
        margin-bottom: rem-calc(22);
    }

    &__info {
        display: block;
        line-height: 150%;
        color: map-deep-get($colors, "white", "default");

        &--underline {
            text-decoration-line: underline;
        }
    }

    &--global {
        background-color: map-deep-get($colors, "brand", "secondary");
        position: relative;
        border-radius: .5rem .5rem 0;
        padding: 42px 10px;
        margin-bottom: 5rem;

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
            border-radius: .5rem 0 0 .5rem;
        }

        &::before {
            position: absolute;
            display: block;
            top: 0;
            bottom: calc(-3rem + 1px);
            left: 100%;
            width: 100vw;
            background-color: map-deep-get($colors, "brand", "secondary");

            @include media-breakpoint-up(md) {
                content: "";
            }
        }

        @include media-breakpoint-down(md) {
            padding: 42px 30px;
        }

        @include media-breakpoint-down(sm) {
            padding: 42px 20px;
        }

        @include media-breakpoint-up(md) {
            margin-top: -80px;
            z-index: 200;
            padding: 42px;
        }
    }

    &__contact-info-spacer {
        position: absolute;
        top: 100%;
        margin-top: -1px;
        width: 50%;
        text-align: right;
        right: 0;
        overflow: hidden;
        height: 3rem;
        border-radius: 0 0 .5rem;

        @include media-breakpoint-up(md) {
            overflow: visible;
        }

        svg {
            height: 3rem;
            width: auto;
        }
    }
}
