@mixin accolade($position: ("before", "after")) {
    &:#{$position} {
        content: "";
        background-image: url("/images/icons/accolade.svg");
        opacity: 0.3;
        position: relative;
        background-size: contain;
        background-repeat: no-repeat;
        display: inline-flex;
        height: 2.5rem;
        width: .675rem;
        top: .5rem;

        @if $position == "before" {
            margin-right: 1rem;
        }

        @if $position == "after" {
            transform: rotate(-180deg);
            margin-left: 1rem;
        }
    }
}

.title {
    position: relative;
    display: inline-flex;
    line-height: 1;

    &--accolade {
        /* stylelint-disable */
        &-both {
            @include accolade("before");
            @include accolade("after");
        }
        /* stylelint-enable */
    }

    &--white {
        color: map-deep-get($colors, "white", "default");
    }

    &--primary {
        color: map-deep-get($colors, "brand", "primary");
    }

    &--primary-dark {
        color: map-deep-get($colors, "brand", "primary-dark");
    }

    &--uppercase {
        letter-spacing: 0.1em;
        text-transform: uppercase;
    }

    &__tag {
        color: map-deep-get($colors, "brand", "primary-light");
    }
}
