.no-slideshow {
    background: $gradient-default;

    &--products {
        background: map-deep-get($colors, "brand", "primary");
        height: 1.25rem;

        @include media-breakpoint-up(sm) {
            height: 2.5rem;
        }

        @include media-breakpoint-up(md) {
            height: 3.75rem;
        }

        @include media-breakpoint-up(xl) {
            height: 5rem;
        }
    }
}