.vacancy-card {
    padding: 2rem;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.16);
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;

    &__title {
        margin-bottom: .5rem;
        color: map-deep-get($colors, "brand", "primary");
    }

    &__subtitle {
        color: transparentize(map-deep-get($colors, "brand", "primary"), .3);
    }
}
