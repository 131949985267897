.backdrop {
    position: absolute;
    display: none;
    width: 100vw;
    height: calc(100vh - 44px);
    top: 44px;
    left: 0;
    right: 0;
    z-index: 0;
    background: linear-gradient(131.99deg, #00336a 0%, rgba(0, 97, 161, 0) 100%);
    opacity: 0.5;

    &--active {
        display: block;
    }

    &--rtl {
        background: linear-gradient(247.98deg, #00336a 0%, rgba(0, 97, 161, 0) 100%);
    }
}
