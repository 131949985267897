$nav-sub-width: 420px;

.navigation {
    $elm: &;
    display: flex;
    height: 100%;
    align-items: center;
    min-width: $nav-sub-width + 32;
    background-color: map-deep-get($colors, "white", "default");
    justify-content: flex-end;
    font-family: $font-family-eurostile;
    letter-spacing: 0.1em;
    position: relative;
    padding-right: 48px;
    flex-flow: row wrap;

    @include media-breakpoint-down(md) {
        flex-direction: column;
    }

    &__list {
        display: flex;
        height: 100%;
        visibility: visible;
        opacity: 1;
        transition: opacity 0.3s ease;

        @include media-breakpoint-down(md) {
            flex-direction: column;
        }
        @include media-breakpoint-down(sm) {
            height: auto;
        }

        &--hide {
            visibility: hidden;
            line-height: 0;
            width: 0;
            opacity: 0;
        }
    }

    &__item {
        font-family: $font-family-eurostile;
        margin-right: rem-calc(48);
        display: flex;
        align-items: center;
        height: 100%;

        @include media-breakpoint-down(md) {
            display: block;
            flex-direction: column;
            align-items: flex-start;
            height: auto;
            letter-spacing: .1em;
            margin-bottom: .5rem;
        }

        &--has-dropdown {
            &:hover {
                #{$elm}__dropdown-wrapper {
                    opacity: 1;
                    transform: translateY(0);
                    pointer-events: auto;
                }
            }
        }
    }

    &__link {
        font-weight: 700;
        color: map-deep-get($colors, "brand", "primary");
        white-space: nowrap;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        height: 100%;

        @include media-breakpoint-down(md) {
            display: inline-block;
            align-items: flex-start;
            color: map-deep-get($colors, "white", "default");
            height: auto;
            margin-bottom: .5rem;
        }
    }

    &__dropdown-wrapper {
        position: absolute;
        top: 100%;
        right: 48px;
        padding-top: 8px;
        opacity: 0;
        transform: translateY(20px);
        transition: 0.3s ease;
        pointer-events: none;

        @include media-breakpoint-down(md) {
            top: unset;
            left: unset;
            right: unset;
            opacity: 1;
            transform: translateY(0);
            padding: 0;
            position: relative;
            width: 100%;
        }
    }

    &__dropdown {
        display: flex;
        width: $nav-sub-width;
        border-radius: 4px;
        box-shadow: $shadow-default;
        background: map-deep-get($colors, "brand", "primary");
        color: map-deep-get($colors, "white", "default");
        padding: 1.5rem 2rem;
        flex-flow: row wrap;

        @include media-breakpoint-down(md) {
            padding: 0;
            background: none;
            flex-direction: column;
            box-shadow: none;
            width: 100%;
        }
    }

    &__dropdown-item:first-of-type {
        flex-basis: 100%;
        text-transform: uppercase;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        font-size: rem-calc(32);
        color: map-deep-get($colors, "brand", "primary-dark");
        opacity: 0.2;
        margin-bottom: $sizer-md;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    &__dropdown-item {
        flex-basis: 50%;
        font-weight: 500;
        line-height: 1.5;
        font-size: 1rem;
        color: map-deep-get($colors, "white", "default");
        margin-bottom: .5rem;

        @include media-breakpoint-down(md) {
            font-weight: 400;
        }

        &--arrow {
            transition: $transition-default;
            opacity: 0;

            svg {
                fill: map-deep-get($colors, "brand", "primary-dark");
            }

            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        &:hover {
            color: map-deep-get($colors, "brand", "primary-dark");

            #{$elm}__dropdown-item--arrow {
                opacity: 1;

                svg {
                    fill: map-deep-get($colors, "brand", "primary-dark");
                }
            }
        }
    }

    &__dropdown-link {
        font-weight: 700;
        color: map-deep-get($colors, "white", "default");
        display: inline-flex;
        align-items: center;
        white-space: nowrap;
        text-transform: uppercase;
        letter-spacing: .1em;

        &--active {
            color: map-deep-get($colors, "brand", "primary-dark");
        }

        &--special {
            color: map-deep-get($colors, "brand", "primary-light");
        }

        @include media-breakpoint-down(md) {
            font-weight: 400;
        }
    }

    &--searching {
        opacity: 0;
    }

    &::before {
        position: absolute;
        background-repeat: no-repeat;
        background-size: contain;
        background-position-x: 6px;
        content: "";
        background-image: url("/images/slope-spacer.svg");
        height: 100%;
        width: rem-calc(110);
        top: 0;
        left: rem-calc(-110);
    }
}
