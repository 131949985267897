.contact-form {
    &__form {
        margin-top: -$sizer-section / 4;
        @include media-breakpoint-up(md) {
            margin-top: -$sizer-section / 2;
        }
        @include media-breakpoint-down(sm) {
            margin-top: $sizer-section / 2;
        }
    }
}
