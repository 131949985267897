/* stylelint-disable */
#CybotCookiebotDialog {
    #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink {
        &.CybotCookiebotDialogActive {
            border-bottom-color: map-deep-get($colors, "brand", "primary") !important;
            color: map-deep-get($colors, "brand", "primary") !important;
        }
        &:hover,
        &:focus-visible {
            color: map-deep-get($colors, "brand", "primary") !important;
        }
    }
    .CybotCookiebotDialogDetailBodyContentCookieLink,
    #CybotCookiebotDialogBodyEdgeMoreDetailsLink,
    #CybotCookiebotDialogBodyLevelButtonsSelectPane label:not([for=CybotCookiebotDialogBodyLevelButtonNecessary]) .CybotCookiebotDialogBodyLevelButtonDescription:hover,
    #CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieProvider:not(.CybotCookiebotDialogDetailBodyContentCookieInfoCount):hover {
        color: map-deep-get($colors, "brand", "primary") !important;
    }
    #CybotCookiebotDialogBodyEdgeMoreDetailsLink {
        &::after {
            border-color: map-deep-get($colors, "brand", "primary") !important;
            position: relative;
            top: .0625rem;
        }
    }
    .CookieCard .CybotCookiebotDialogDetailBodyContentCookieContainerButton {
        transition: $transition-default;
        &:hover,
        &:focus-visible {
            color: map-deep-get($colors, "brand", "primary") !important;
            &:before {
                border-color: map-deep-get($colors, "brand", "primary") !important;
            }
        }
        &::before {
            transition: $transition-default;
        }
    }
    .CybotCookiebotDialogBodyButton {
        border-color: map-deep-get($colors, "brand", "primary") !important;
        transition: $transition-default;
        &:hover,
        &:focus-visible {
            background-color: rgba(map-deep-get($colors, "brand", "primary"), .05) !important;
            border-color: rgba(map-deep-get($colors, "brand", "primary"), .75) !important;
            opacity: 1 !important;
        }
    }
    #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
        color: map-deep-get($colors, "white", "default") !important;
        background-color: map-deep-get($colors, "brand", "primary") !important;
        &:hover,
        &:focus-visible {
            background-color: rgba(map-deep-get($colors, "brand", "primary"), .75) !important;
            background-color: rgba(map-deep-get($colors, "brand", "primary"), .75) !important;
        }
    }
    #CybotCookiebotDialogDetailFooter a {
        color: map-deep-get($colors, "brand", "primary") !important;
    }
    input:checked + .CybotCookiebotDialogBodyLevelButtonSlider {
        background-color: map-deep-get($colors, "brand", "primary") !important;
    }
    input[type=checkbox],
    input[type=radio] {
        z-index: 0;
    }
}
#CookiebotWidget {
    .CookiebotWidget-body .CookiebotWidget-consents-list svg {
        fill: map-deep-get($colors, "brand", "primary") !important;
    }
    .CookiebotWidget-show-consent-details {
        color: map-deep-get($colors, "brand", "primary") !important;
        svg {
            fill: map-deep-get($colors, "brand", "primary") !important;
            position: relative;
            top: .0625rem;
        }
    }
    #CookiebotWidget-buttons button {
        border-color: map-deep-get($colors, "brand", "primary") !important;
        transition: $transition-default;
        &:hover,
        &:focus-visible {
            background-color: rgba(map-deep-get($colors, "brand", "primary"), .05) !important;
            border-color: rgba(map-deep-get($colors, "brand", "primary"), .75) !important;
            opacity: 1 !important;
        }
    }
    #CookiebotWidget-buttons #CookiebotWidget-btn-change {
        background-color: map-deep-get($colors, "brand", "primary") !important;
        &:hover,
        &:focus-visible {
            background-color: rgba(map-deep-get($colors, "brand", "primary"), .75) !important;
        }
    }
}


._CookieScriptReportPageCheckboxes {
    margin: 10px 5px!important;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}

._CookieScriptReportPageCheckbox {
    margin: 0 5px 0 0px;
   & input[type=checkbox] {
       background-color: rgba(map-deep-get($colors, "brand", "primary"),1) !important;
       width: 20px;
   }
}

._CookieScriptReportPageCheckboxLabel {
    align-items: center;
    display: flex;
}

._CookieScriptReportPageCheckboxInput {
    transform: none !important;
    padding: 0;
    margin: 0 5px 0 0;
    border: 0;
    background-color: rgba(map-deep-get($colors, "brand", "primary"),1 ) !important;

    & input[type=checkbox] {
        background-color: rgba(map-deep-get($colors, "brand", "primary"),1 ) !important;

    }
}

._CookieScriptReportPageCheckboxText {
    display: inline-block;
    font-size: 14px!important;
    line-height: 12px!important;
    wrap-option: no-wrap!important;
    margin: 0 0 0 5px;
}

._CookieScriptReportPageSaveSettingButton {
    margin-top: -$sizer-lg;
    background-color: map-deep-get($colors, "brand", "primary") !important;
    border-radius: 5px!important;
    box-shadow: none!important;
    padding: $sizer-md;
    color: map-deep-get($colors, "white", "default");
}

._CookieScriptReportPageTable {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

._CookieScriptReportPageTableTd, ._CookieScriptReportPageTableTh {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

._CookieScriptReportPageTable caption {
    font-weight: 600;
    padding: 10px;
    text-align: left;
    color: #6f6f6f;
    font-size: 12px;
}
/* stylelint-enable */
button._CookieScriptReportPageSaveSettingButton{
    cursor: pointer !important;
    letter-spacing: 0.05em !important;
    font-family: $font-family-eurostile !important;
    padding: 1rem 2rem !important;
    min-height: 3rem !important;
    line-height: 1.5rem !important;
    font-size: 1.125rem !important;
    border-radius: $border-radius !important;
    background-color: map-deep-get($colors, "brand", "primary");
    color: map-deep-get($colors, "white", "default") !important;
    border: 1px solid transparent !important;
    transition: $transition-default !important;
    font-weight: 800 !important;
    text-align: center !important;
    white-space: nowrap !important;
    display: inline-flex !important;
    align-items: center !important;
    margin: 0 .5rem .5rem 0 !important;

    &:hover {
        transform: scale(1.025) !important;
        color: map-deep-get($colors, "white", "default") !important;
        box-shadow: $shadow-hover !important;
        text-decoration: none !important;
    }
}

