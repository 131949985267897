// Rich text styling layout
/* stylelint-disable */
.rich-text-area {
    line-height: 1.5;

    h2, h3 {
        color: map-deep-get($colors, 'brand', 'primary');
    }

    h2 {
        font-size: 1.25rem;

        @include media-breakpoint-up(md) {
            font-size: 1.5rem;
        }
    }

    h3 {
        font-size: 1.125rem;

        @include media-breakpoint-up(md) {
            font-size: 1.25rem;
        }
    }

    @media (min-width: 1250px) {
        .video-wrapper {
            width: 100%;

            &__iframe {
                height: 315px;
            }
        }
    }

    img {
        max-width: 100%;
    }

    a:not(.button) {
        text-decoration: underline;
    }

    ul {
        margin: rem-calc(20) 0;

        li {
            position: relative;
            padding-left: 20px;
            margin-bottom: 0;

            &::before {
                content: "";
                background: map-deep-get($colors, "brand", "secondary");
                width: 0.5em;
                height: 0.5em;
                border-radius: 50%;
                position: absolute;
                left: 0;
                top: .65em;
            }
        }

        ul {
            margin: 0;
        }
    }

    &--dark {
        h2, h3, p, li {
            color: map-deep-get($colors, "white", "default");
        }
    }
}

/* stylelint-enable */
