.footer-cta {
    background: map-deep-get($colors, "brand", "primary-dark");
    color: map-deep-get($colors, "white", "default");
    position: relative;
    margin-bottom: 1.25rem;
    padding-top: 1.5rem;
    $elm: &;

    @include media-breakpoint-up(sm) {
        margin-bottom: 2.5rem;
    }

    @include media-breakpoint-up(md) {
        margin-bottom: 3.75rem;
        padding-top: 0;
    }

    @include media-breakpoint-up(xl) {
        margin-bottom: 5rem;
    }

    &__shape-top {
        position: absolute;
        margin-top: 0;
        margin-bottom: -1px;
        bottom: 100%;
        transform: scaleY(-1);

        @include media-breakpoint-up(md) {
            transform: scale(-1);
            margin-top: 0;
            margin-bottom: -1px;
        }
    }

    &__shape-bottom {
        position: absolute;
        top: 100%;
        z-index: 1;

        @include media-breakpoint-up(md) {
            transform: scaleX(-1);
        }
    }

    &__content {
        position: relative;
        z-index: 2;
        margin-bottom: calc(-1.25rem - 1.75rem);

        @include media-breakpoint-up(sm) {
            margin-bottom: calc(-2.5rem - 1.75rem);
        }

        @include media-breakpoint-up(md) {
            margin-bottom: calc(-3.75rem - 1.75rem);
        }

        @include media-breakpoint-up(xl) {
            margin-bottom: calc(-5rem - 1.75rem);
        }
    }

    &__button {
        margin: 0;
    }

    &--portal {
        padding-bottom: 2.5rem;
        margin-bottom: 0;

        @include media-breakpoint-up(md) {
            padding-bottom: 5rem;
        }

        #{$elm}__content {
            margin-bottom: 0;
        }
    }
}