.filter {
    display: inline-flex;

    @include media-breakpoint-down(sm) {
        height: auto;
    }

    &__search {
        position: relative;
        margin-bottom: $sizer-xxl;

        &__input {
            align-items: center;
            justify-content: center;
            border: 1px solid map-deep-get($colors, "brand", "primary");
            font-weight: $font-weight-bold;
            font-family: $font-family-eurostile;
            height: rem-calc(40);
            min-width: rem-calc(78);
            border-radius: rem-calc(20);
            color: map-deep-get($colors, "brand", "primary");
        }

        &__button {
            display: flex;
            border: 0;
            background: 0;
            position: absolute;
            right: 10px;
            top: 9px;

            svg {
                fill: map-deep-get($colors, "brand", "primary");

                @include media-breakpoint-down(md) {
                    fill: map-deep-get($colors, "white", "default");
                }
            }
        }
    }

    &__toggle {
        display: none;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        border: 1px solid map-deep-get($colors, "brand", "primary");
        font-weight: $font-weight-bold;
        height: rem-calc(40);
        min-width: rem-calc(78);
        border-radius: rem-calc(20);
        background-color: map-deep-get($colors, "white", "default");
        color: map-deep-get($colors, "brand", "primary");
        padding: 0 $sizer-md;
        margin-bottom: $sizer-md;

        @include media-breakpoint-down(sm) {
            display: flex;
        }
    }

    &__items {
        display: none;
        flex-direction: row;
        flex-wrap: wrap;
        gap: $sizer-sm;

        &--active {
            display: flex;
        }

        &--md {
            @include media-breakpoint-up(md) {
                display: flex;
            }
        }

        @include media-breakpoint-down(sm) {
            flex-direction: column;
            flex-basis: 100%;
        }
    }

    /* stylelint-disable */
    li {
        position: relative;

        @include media-breakpoint-down(sm) {
            margin-bottom: $sizer-md;
        }
    }
    /* stylelint-enable */

    &__item {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid map-deep-get($colors, "brand", "primary");
        font-weight: $font-weight-bold;
        font-family: $font-family-eurostile;
        height: rem-calc(40);
        min-width: rem-calc(78);
        border-radius: rem-calc(20);
        background-color: map-deep-get($colors, "white", "default");
        color: map-deep-get($colors, "brand", "primary");
        padding: 0 $sizer-md;
        letter-spacing: 0.05em;

        &:hover {
            background: map-deep-get($colors, "brand", "primary");
            color: map-deep-get($colors, "white", "default");
            opacity: 0.8;

            /* stylelint-disable */
            svg path {
                fill: map-deep-get($colors, "white", "default");
            }
            /* stylelint-enable */
        }

        @include media-breakpoint-down(sm) {
            height: rem-calc(40);
            margin-left: 0;
        }

        /* stylelint-disable */
        svg {
            margin-left: $sizer-xs;
            width: rem-calc(16);
            height: rem-calc(16);

            path {
                fill: map-deep-get($colors, "brand", "primary");
            }
        }
        /* stylelint-enable */

        &--active {
            background: map-deep-get($colors, "brand", "primary");
            color: map-deep-get($colors, "white", "default");

            /* stylelint-disable */
            svg path {
                fill: map-deep-get($colors, "white", "default");
            }
            /* stylelint-enable */
        }
    }

    &__dropdown {
        display: none;
        flex-grow: 1;
        position: absolute;
        flex-direction: column;
        top: $sizer-xxl + $sizer-sm;
        z-index: 10;
        background-color: map-deep-get($colors, "brand", "primary");
        border-radius: rem-calc(4);
        padding: $sizer-md;
        white-space: nowrap;
        margin-left: $sizer-sm;

        @include media-breakpoint-down(sm) {
            top: $sizer-md;
            position: relative;
            padding: 0;
            margin-left: 0;
            background-color: transparent;
        }

        &--open {
            display: flex;
        }

        &__item {
            display: flex;
            color: map-deep-get($colors, "white", "default");
            font-family: $font-family-eurostile;
            font-weight: $font-weight-bold;
            line-height: 125%;
            letter-spacing: 0.05em;

            @include media-breakpoint-down(sm) {
                height: 40px;
                align-items: center;
                justify-content: center;
                color: map-deep-get($colors, "brand", "primary");
                border: 1px solid map-deep-get($colors, "brand", "primary");
                background-color: map-deep-get($colors, "white", "default");
                border-radius: rem-calc(20);
            }
        }
    }
}
