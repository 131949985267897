.container {
    padding-left: .875rem;
    padding-right: .875rem;

    @include media-breakpoint-up(lg) {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    &--foreground {
        position: relative;
        z-index: 2;
    }
}
