﻿.process-card {
    $elm: &;
    cursor: pointer;
    display: block;
    position: relative;
    overflow: hidden;
    border-radius: $border-radius;
    box-shadow: $shadow-default;
    background: map-deep-get($colors, "white", "default");
    margin-bottom: 20px;
    transition: $transition-default;

    @include media-breakpoint-down(sm) {
        padding-top: 50%;
    }
    @include media-breakpoint-down(xs) {
        padding-top: 75%;
    }

    &__gradient {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(138.37deg, #00336a 0%, rgba(0, 97, 161, 0) 100%);
        opacity: 0.25;
        border-radius: 4px;
    }

    &__image {
        padding-bottom: 2rem;
    }

    &__content {
        display: flex;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        flex-direction: column;
        justify-content: flex-end;
        padding: 1.5rem;
        transition: $transition-default;

        @include media-breakpoint-up(md) {
            padding: 1.5rem 2rem;
        }

        &::before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            border-radius: $border-radius;
            background: map-deep-get($colors, "brand", "primary-dark");
            opacity: .15;
            transition: $transition-default;
        }
    }

    &__title {
        color: map-deep-get($colors, "white", "default");
        text-shadow: $shadow-text;
        position: relative;
        margin-bottom: 0;
        hyphens: auto;
        transition: $transition-default;
        max-height: 4.675rem;

        @include media-breakpoint-up(sm) {
            max-height: none;
        }
    }

    &__function-title {
        color: map-deep-get($colors, "brand", "secondary");
        text-shadow: $shadow-text;
        font-family: $font-family-eurostile;
        font-size: 0.875rem;
        position: relative;
        margin-bottom: 0;
        font-weight: 700;
        letter-spacing: .1em;
        margin-top: .25rem;
        line-height: 1;
        transition: $transition-default;
        text-transform: uppercase;
    }

    &__link, &__link:hover {
        text-shadow: none;
        line-height: 100%;

        svg {
            margin-right: rem-calc(12);
            width: rem-calc(20);
            height: rem-calc(20);
        }
    }

    &__linkedin-link {
        opacity: 0;
        position: absolute;
        top: 1rem;
        right: 1rem;
    }

    &__tag {
        color: map-deep-get($colors, "white", "default");
        font-family: $font-family-eurostile;
        font-size: 14px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        opacity: 1;
        font-weight: 700;
        position: relative;
        flex-grow: 1;

        &::after {
            position: absolute;
            margin-left: $sizer-xs;
            opacity: 0;
            content: "";
            background-image: url("/images/icons/accolade.svg");
            background-size: contain;
            background-repeat: no-repeat;
            transition: $transition-default;
            height: rem-calc(20);
            width: rem-calc(6);
            transform: translateX(1rem) rotate(180deg);
        }
    }

    &__paragraph {
        display: none;

        @include media-breakpoint-up(sm) {
            display: block;
        }
    }

    &__paragraph, &__links {
        line-height: 0;
        margin-bottom: 0;
        max-height: 0;
        position: relative;
        opacity: 0;
        overflow: hidden;
        transition: $transition-default;
        text-shadow: $shadow-text;

        @include media-breakpoint-down(xs) {
            font-size: .6rem;
        }
    }

    &__links {
        max-height: 0;
        transition: $transition-default;
    }

    &__link {
        margin-bottom: 0;

        @include media-breakpoint-down(md) {
            margin-bottom: 5px;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &:hover {
        box-shadow: $shadow-hover;
        transform: scale(1.025);

        #{$elm}__content {
            &::before {
                opacity: .85;
            }
        }

        #{$elm}__tag{
            &::after {
                opacity: 0.3;
                transform: translateX(0) rotate(180deg);
            }
        }

        #{$elm}__title, #{$elm}__paragraph {
            color: map-deep-get($colors, "white", "default");
            margin-bottom: 1rem;
        }

        #{$elm}__paragraph {
            opacity: 1;
            max-height: 6.5rem;
            line-height: 1.3;
        }

        #{$elm}__links {
            opacity: 1;
            max-height: 4rem;
        }

        #{$elm}__linkedin-link {
            opacity: 1;
        }

        #{$elm}__function-title {
            margin-top: -.75rem;
            margin-bottom: 1rem;

            @include media-breakpoint-up(sm) {
                margin-bottom: 0;
            }
        }

        #{$elm}link {
            margin-bottom: .5rem;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}
