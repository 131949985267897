.navigation_mob {
    display: none;
    position: absolute;
    z-index: 8;
    width: 100vw;
    border-radius: 0;
    margin: 0;
    height: 100vh;
    flex-direction: column;
    left: 0;
    top: 0;
    padding: 125px 20px 0;
    box-shadow: $shadow-default;
    background: $gradient-left-down;
    overflow-y: scroll;

    &--active {
        display: block;
    }
}
